import React, {useEffect, useState} from 'react';
import {Table, Row, Col, Tooltip, Button, DatePicker, InputNumber, Input, Drawer, Popover, Image, Empty} from 'antd';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../store/root-store';
import {FileExcelOutlined, FilterOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {CSVLink} from "react-csv";
import styles from './styles.module.scss';
import moment from 'moment';
import _ from 'lodash';
import EditCastForm from '../editCast';

export const ExtrasTable: React.FC = observer(() => {
    const {castStore: {getAllCasts, loading, cast, filterCasts, notificationMessage, deleteCasts}} = useStore('');
    const {imagesStore: {getAllImages, images}} = useStore('');
    
    useEffect(() => {
        getAllCasts();
    }, []);

    useEffect(() => {
        getAllImages();
    }, [])
    
    const [tableDataSource, setTableDataSource] = useState<Array<any>>([...cast]);

    const [editId, setEditId] = useState<number>(0);
    const [openDrawer, setOpenDrawer] = useState(false);

    //Filter states
    const [weightFilterFrom, setWeightFilterFrom] = useState<number | null>(null);
    const [weightFilterTo, setWeightFilterTo] = useState<number | null>(null);

    const [heightFilterFrom, setHeightFilterFrom] = useState<number | null>(null);
    const [heightFilterTo, setHeightFilterTo] = useState<number | null>(null);
    
    const [yearsOfExperienceFilterFrom, setYearsOfExperienceFilterFrom] = useState<number | null>(null);
    const [yearsOfExperienceFilterTo, setYearsOfExperienceFilterTo] = useState<number | null>(null);

    const [dateFilterFrom, setDateFilterFrom] = useState<string>();
    const [dateFilterTo, setDateFilterTo] = useState<string>();

    // const [isActiveFilterin, setIsActiveFiltering] = useState(false);

    // useEffect(() => {
    //     setDateFilterFrom(getLowestDate());
    //     setDateFilterTo(getHighestDate());
    //     setWeightFilterTo(getMaxValue('weight'));
    //     setHeightFilterTo(getMaxValue('height'));
    //     setYearsOfExperienceFilterTo(getMaxValue('yearsOFExperience'));
    // }, [loading]);

    const resetFilters = () => {
        getAllCasts();
        setDateFilterFrom("");
        setDateFilterTo("");
        setWeightFilterFrom(null);
        setHeightFilterFrom(null);
        setWeightFilterTo(null);
        setHeightFilterTo(null);
        setYearsOfExperienceFilterFrom(null);
        setYearsOfExperienceFilterTo(null);
    };

    const handleSearch = (e: any) => {
        if(e.target.value.length > 0) {
            const searchResults = [...cast]?.filter(data => data?.fullName?.toLowerCase().includes(e.target.value.toLowerCase()));
            setTableDataSource(searchResults);
        }
        else {
            setTableDataSource([...cast]);
        }
    };

    const handleDeleteCasts = (id: number) => {
        deleteCasts(id);
        // getAllCasts();
    }

    const handleCustomFiltering = () => {
        //@ts-ignore
        const filterResults = filterCasts(dateFilterFrom, dateFilterTo, heightFilterFrom, heightFilterTo, weightFilterFrom, weightFilterTo, yearsOfExperienceFilterFrom, yearsOfExperienceFilterTo);
        // setTableDataSource(filterResults);
    };

    useEffect(() => {
        setTableDataSource([...cast]);
        getAllImages();
        console.log('loading', loading)
    }, [loading]);

    const handleShowDistinctFilters = (filterable: any) => {
         // @ts-ignore: Unreachable code error
        const distinctFilters: Array<any> = [...new Set(tableDataSource.map(member => member[filterable]))]
        return distinctFilters;
    };

    const handleEditCast = (id: number) => {
        setEditId(id);
        setOpenDrawer(true);
    };

    console.log('images', [...images])

    const columns = [
        {
            title: 'IME I PREZIME',
            dataIndex: 'fullName',
            key: 'fullName',
            elipsis: true,
            fixed: 'left' as 'left',
            width: 150,
            render: (fullName: string, data: any) => {
                return (
                    <div className={styles.cellContent}>    
                        <span className={styles.cellContnetTitle}  style={{color: '#EE3E95'}}>
                            <Popover title={fullName} trigger={'click'}
                                content={
                                    <Image.PreviewGroup>
                                        {[...images].filter(img => img.castId === data.id).map(data => {
                                            return (
                                                <>
                                                <Image
                                                    width={100}
                                                    src={`https://api.bossteamaplikacija.rs/images/${data.filename}`}
                                                />
                                                </>
                                            )
                                        })}
                                    </Image.PreviewGroup>
                                }
                            >
                                {fullName} 
                            </Popover>
                        </span>
                        <span className={styles.cellContentSubtitle}>
                            {data.nickname}
                        </span>
                    </div>
                )
            },
        },
        {
            title: 'KATEGORIJA',
            dataIndex: 'type',
            key: 'type',
            fixed: 'left' as 'left',
            width: 120,
            filters: handleShowDistinctFilters('type').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.type?.indexOf(value) === 0,
            render: (type: string) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            <Tooltip title={type}>
                                {type ? type : '-'}
                            </Tooltip>
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'POL',
            dataIndex: 'sex',
            key: 'sex',
            elipsis: true,
            filters: handleShowDistinctFilters('sex').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.sex?.indexOf(value) === 0,
            width: 60,
            render: (sex: string, data: any) => {
                return (
                    <div className={styles.cellContent}>    
                        <span className={styles.cellContnetTitle}  style={{color: '#EE3E95'}}>
                            <Tooltip title={sex}>
                                {sex === 'Muški' ? 'M' : 'Ž' }
                            </Tooltip>
                        </span>
                    </div>
                )
            },
        },
        {
            title: 'EMAIL ADRESA',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            render: (emailAddress: string, data: any) => {
                return (
                    <div className={styles.cellContent}>    
                        <span className={styles.cellContnetTitle}>
                            <Tooltip title={emailAddress}>
                                {emailAddress}
                            </Tooltip>
                        </span>
                        <span className={styles.cellContentSubtitle}>
                            <Tooltip title={data.contactPhone}>
                                    {data.contactPhone}
                            </Tooltip>
                        </span>
                    </div>
                )
            },
        },
        {
            title: 'ADRESA',
            dataIndex: 'address',
            key: 'adress',
            filters: handleShowDistinctFilters('city').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.city?.indexOf(value) === 0,
            render: (address: string, data: any) => {
                return (
                    <span>{address} {data.city}</span>
                )
            }
        },
        {
            title: 'JMBG',
            dataIndex: 'jmbg',
            key: 'jmbg',
            render: (jmbg: string) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            <Tooltip title={jmbg}>
                                {jmbg ?jmbg : '-'}
                            </Tooltip>
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'DATUM ROĐENJA',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            sorter: (a: any, b: any) => moment(a.dateOfBirth).unix() - moment(b.dateOfBirth).unix() ,
            render: (age: string) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            {age ? moment(age).format('DD-MM-YYYY') : '-'}
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'VISINA',
            dataIndex: 'height',
            key: 'height',
            width: 100,
            sorter: (a: any, b: any) => a.height - b.height,
            render: (height: number) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            {height ? `${height}cm` : '-'}
                        </span>
                    </div>
                )
            }
        },
        {
            title: 'TEŽINA',
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a: any, b: any) => a?.weight - b?.weight,
            render: (weight: number) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            {weight ? `${weight}kg` : '-'}
                        </span>
                    </div>
                )
            }
        },
        {
            title: 'BOJA KOSE',
            dataIndex: 'hairColor',
            key: 'hairColor',
            filters: handleShowDistinctFilters('hairColor').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.hairColor?.indexOf(value) === 0,
            render: (hairColor: string) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            <Tooltip title={hairColor}>
                                {hairColor ? hairColor : '-'}
                            </Tooltip>
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'DUŽINA KOSE',
            dataIndex: 'hairSize',
            key: 'hairSize',
            filters: handleShowDistinctFilters('hairSize').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.hairSize?.indexOf(value) === 0,
            render: (hairSize: string) => {
                return (
                    <div className={styles.cellContent}>
                        <span className={styles.singleTitle}>
                            <Tooltip title={hairSize}>
                                {hairSize ? hairSize : '-'}
                            </Tooltip>
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'BOJA OČIJU',
            dataIndex: 'eyeColor',
            key: 'eyeColor',
            filters: handleShowDistinctFilters('eyeColor').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.eyeColor?.indexOf(value) === 0,
        },
        {
            title: 'RASA',
            dataIndex: 'race',
            key: 'race',
            filters: handleShowDistinctFilters('race').map(data => ({text: data, value: data})),
            onFilter: (value: any, record: any) => record?.race?.indexOf(value) === 0,
        },
        {
            title: 'GODINE ISKUSTVA',
            dataIndex: 'yearsOFExperience',
            key: 'yearsOFExperience',
            sorter: (a: any, b: any) => a.yearsOFExperience - b.yearsOFExperience,
        },
        {
            title: 'OPIS ISKUSTVA',
            dataIndex: 'experienceDescription',
            key: 'experienceDescription',
        },
        {
            title: 'DODATNI OPIS',
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
        },
        {
            title: 'SPECIFIČNOST',
            dataIndex: 'specificity',
            key: 'specificity',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            fixed: 'right' as 'right',
            width: 50,
            render: (id: any, data: any) => {
                return <>
                        <DeleteOutlined style={{marginRight: '10px', color: 'red'}} onClick={() => handleDeleteCasts(id)}/>
                        <EditOutlined style={{color: '#FCC100'}} onClick={() => handleEditCast(id)} />
                       </>
            }
        },
    ];

    const csvHeaders = [
        {label: "Ime i prezime", key: "fullName"},
        {label: "Kategorija", key: "type"},
        {label: "Datum rođenja", key: "dateOfBirth"},
        {label: "Kontakt telefon", key: "contactPhone"},
        {label: "Kontakt mejl adresa", key: "emailAddress"},
        {label: "Nadimak", key: "nickname"},
        {label: "Jmbg", key: "jmbg"},
        {label: "Adresa", key: "address"},
        {label: "Grad", key: "city"},
        {label: "Visina", key: "height"},
        {label: "Težina", key: "weight"},
        {label: "Boja kose", key: "hairColor"},
        {label: "Boja očiju", key: "eyeColor"},
        {label: "Rasa", key: "race"},
        {label: "Iskustvo", key: "experienceDescription"},
        {label: "Godine iskustva", key: "yearsOFExperience"},
        {label: "Specifičnost", key: "specificity"},
        {label: "Dodatni opis", key: "additionalInfo"},
      ];

    return (
        <div className={styles.tableContainer}>
            <Row gutter={24}>
                <Col span={24}>
                    <h1 className={styles.title}>Tabela statista</h1>
                </Col>
                <Col span={24}>
                    <div className={styles.functions}>
                        <CSVLink
                            data={tableDataSource}
                            filename={"bazaStatista.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            headers={csvHeaders}
                            >
                            <Button className={styles.mainBtn} icon={<FileExcelOutlined />} ghost>Eksportuj kao Eksel fajl</Button>
                        </CSVLink>   
                        <Input className={styles.searchInput} style={{width: '200px', marginLeft: '20px'}} placeholder='Pretraži statistu...' onChange={handleSearch} />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.customFilters}>
                        <div className={styles.filterBox}>
                            <span className={styles.filterLabel}>
                                Filtriraj datume rođenja
                            </span>
                            <div className={styles.filterValues}>
                                <DatePicker placeholder='Od' onChange={(e, dateString) => setDateFilterFrom(dateString)}/> - <DatePicker placeholder='Do' onChange={(e, dateString) => setDateFilterTo(dateString)}/>
                            </div>
                        </div>
                        <div className={styles.filterBox}>
                            <span className={styles.filterLabel}>
                                Filtriraj visinu
                            </span>
                            <div className={styles.filterValues}>
                                <InputNumber onChange={(e) => setHeightFilterFrom(e as number)}/> - <InputNumber onChange={(e) => setHeightFilterTo(e as number)} />
                                <Tooltip title="Filtriraj">
                                    {/* <Button type="primary" style={{backgroundColor: '#ee3e95', border: 'none', marginLeft: 10}} shape="circle" icon={<FilterOutlined />}
                                    /> */}
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.filterBox}>
                            <span className={styles.filterLabel}>
                                Filtriraj težinu
                            </span>
                            <div className={styles.filterValues}>
                                <InputNumber onChange={(e) => setWeightFilterFrom(e as number)}/> - <InputNumber onChange={(e) => setWeightFilterTo(e as number)} />
                            </div>
                        </div>
                        <div className={styles.filterBox}>
                            <span className={styles.filterLabel}>
                                Filtriraj godine iskustva
                            </span>
                            <div className={styles.filterValues}>
                                <InputNumber onChange={(e) => setYearsOfExperienceFilterFrom(e as number)}/> - <InputNumber onChange={(e) => setYearsOfExperienceFilterTo(e as number)} />
                                <Tooltip title="Filtriraj">
                                </Tooltip>
                            </div>
                        </div>
                        <Tooltip title="Filtriraj">
                            <Button type="primary" style={{backgroundColor: '#ee3e95', border: 'none', marginLeft: 10}} shape="circle" icon={<FilterOutlined />}
                                onClick={() => handleCustomFiltering()}
                            />
                        </Tooltip>
                        <Button type="primary" style={{backgroundColor: '#ee3e95', border: 'none', marginLeft: 10}}                  
                                onClick={() => resetFilters()}
                            >Resetuj filtere </Button>
                    </div>
                </Col>
                {tableDataSource && tableDataSource.length > 0 && notificationMessage != 'Nema rezultata'  ?
                <Col span={24}>
                    <Table 
                        showSorterTooltip={{title: 'Klikni za sortiranje'}}
                        columns={columns}
                        dataSource={tableDataSource}
                        loading={loading}
                        size={'small'}
                        scroll={{x: '150%'}}
                        rowClassName={styles.tableRow}
                        className={styles.castTable}
                        
                        />
                </Col>
                : <Empty description='Nema rezultata!' style={{margin: '0 auto', color: '#fff'}} />
                }
                <Drawer 
                    placement="right"
                    closable={true}
                    visible={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    width={600}
                    // visible={visible}
                >
                    <EditCastForm id={editId}/>
                </Drawer>
            </Row>
        </div>
    );
});