import {useState} from "react";
import {observer} from "mobx-react-lite";
import {Row, Col, Form, Input, Button, DatePicker, InputNumber, Select, Radio, message} from 'antd';
import {useStore} from '../../../store/root-store';
import styles from './styles.module.scss';
import axios from 'axios';

const {TextArea} = Input;

export const NewCastForm = observer(() => {

    const {castStore: {createNewUser, cast}} = useStore('');
    const {imagesStore: {saveImageName}} = useStore('');

    const onFinish = (values: any) => {
        createNewUser(values.type, values.fullName, values.dateOfBirth, values.nickname, values.jmbg, values.address, values.city, values.height, values.weight, values.hairColor, values.hairSize, values.eyeColor, values.race, values.experienceDescription, values.yearsOfExperience, values.specificity, values.contactPhone, values.emailAddress, values.additionalInfo, values.sex);
        //@ts-ignore
        const lastInsertedId = [...cast][[...cast].length - 1].id + 1;
        saveImageName(lastInsertedId, uploadNames);
        successMsg();
    };
    
    const onFinishFailed = (errorInfo: any) => {
        errorMsg();
   };

    const successMsg = () => {
        message.success('Statista je uspešno dodat u bazu!', 3);
    };

    const errorMsg = () => {
        message.error('Molimo popunite sva obavezna polja, statista nije dodat!', 3);
    };
    
    const sexOptions = [
        {label: <span style={{color: '#152238'}}>Muški</span>, value: 'Muški'},
        {label: <span style={{color: '#152238'}}>Ženski</span>, value: 'Ženski'}
    ];

    const uploadNames: Array<string> = [];
 
    const handleUpload = (event: any) => {
        const data = new FormData();
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            data.append(`file`, files[i]);
            uploadNames.push(files[i].name);
        };

        axios.post('https://api.bossteamaplikacija.rs/api/cast/upload-image/', data)
            .then((res) => {
            console.log(res)
            });
        };

    return (
        <div className={styles.formContainer}>
            <Row gutter={24}>
                <Col span={24} className={styles.title}>
                    <h1>Forma za dodavanje novog statiste</h1>
                    <span>polja oznacena zvezdicom su obavezna i potrebno je uneti neku vrednost</span>
                </Col>
                <Col span={24}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                     <Form.Item
                        label="Kategorija"
                        name="type"
                        rules={[{ required: true, message: 'Kategorija je obavezna' }]}
                    >
                      <Select>
                            <Select.Option value="Glumac">Glumac</Select.Option>
                            <Select.Option value="Dete glumac">Dete glumac</Select.Option>
                            <Select.Option value="Bend">Bend</Select.Option>
                            <Select.Option value="Muzicar">Muzicar</Select.Option>
                            <Select.Option value="Hostesa">Hostesa</Select.Option>
                            <Select.Option value="Glumac amater">Glumac amater</Select.Option>
                            <Select.Option value="Bilder">Bilderi</Select.Option>
                            <Select.Option value="Drugo">Drugo</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="Ime i prezime"
                        name="fullName"
                        rules={[{ required: true, message: 'Ime i prezime je obavezno' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item label="Slike" name='file'>
                        <input type="file" name="file" onChange={handleUpload} multiple/>
                    </Form.Item>
                    <Form.Item
                        label='Pol'
                        name='sex'
                        rules={[{required: true, message: 'Pol je obevezan'}]}
                    >
                        <Radio.Group
                            options={sexOptions}
                            optionType='button'
                        />
                    </Form.Item>
                    <Form.Item
                        label="Kontakt telefon"
                        name="contactPhone"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Kontakt email adresa"
                        name="emailAddress"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Datum rođjenja"
                        name="dateOfBirth"
                        rules={[{ required: true, message: 'Datum rodjenja je obavezan' }]}
                    >
                    <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label="Nadimak"
                        name="nickname"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="JMBG"
                        name="jmbg"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item
                        label="Adresa"
                        name="address"
                        rules={[{ required: true, message: 'Adresa ja obavezna' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Grad"
                        name="city"
                        rules={[{ required: true, message: 'Grad je obavezan' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Visina"
                        name="height"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="Tezina"
                        name="weight"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>
            
                    <Form.Item
                        label="Boja kose"
                        name="hairColor"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Dužina kose"
                        name="hairSize"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Boja očiju"
                        name="eyeColor"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Rasa"
                        name="race"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Opis iskustva"
                        name="experienceDescription"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <TextArea />
                    </Form.Item>

                    <Form.Item
                        label="Godine iskustva"
                        name="yearsOfExperience"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label="Specifičnost"
                        name="specificity"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <TextArea />
                    </Form.Item>
            
                    <Form.Item
                        label="Dodatne informacije / opis"
                        name="additionalInfo"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                        <TextArea />
                    </Form.Item>
            
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Dodaj
                    </Button>
                    </Form.Item>
                </Form>
                </Col>
            </Row>
        </div>
    )
});

export default NewCastForm;