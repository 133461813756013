import {types, flow, cast} from "mobx-state-tree";
import {addImage, getImages} from "../../api/cast/api";

const imagesModel = types.model({
    id: types.maybeNull(types.number),
    filename: types.maybeNull(types.string),
    castId: types.maybeNull(types.number),
})

export const ImagesStore = types.model({
    images: types.array(imagesModel),
}).actions(self => {
    const saveImageName = flow(function*(id, names) {
        try {
            const response = yield addImage(id, names);
            if(response) {
                self.images = response.data.images;
            }
        } catch(e) {
            console.log(e);
        }
    });

    const getAllImages = flow(function*() {
        try {
            const response = yield getImages();
            if(response) {
                self.images = response.data.images;
            }
        } catch (e) {
            console.log(e);
        }
    })

    return {saveImageName, getAllImages};    
});

export const initImagesStore = () => {
    return ImagesStore.create({
        images: [],
    });
};