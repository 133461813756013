import Dashboard from './components/dashboard';
import './App.css';
import 'antd/dist/antd.css';

function App() {
  return (
    <Dashboard />
  );
}

export default App;
